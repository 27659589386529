<template>
  <main class="kb-main" id="kb-home">
    <div class="main-content min-component">
      <!-- content-section -->
      <article class="content-section section-banner">
        <!--        <video :src="bannerVideo" autoplay="" muted="muted" loop=""></video>-->
        <swiper class="banner-container js-swiper-banner"
                :modules="modules"
                :speed="750"
                effect="fade"
                :pagination="{
                  el: '.banner-pagination',
                  clickable: true
                }"
                :autoplay="{
                  delay: 5000,
                  disableOnInteraction: false,
                  stopOnLast: true,
                }"
                @slideChange="slideChange"
                @swiper="setControlledSwiper"
                :style="slideStyle"
        >
          <swiper-slide v-if="isBannerLoading && baners.length === 0" class="banner">
            <img :src="bannerImg" alt="" style="width: 100%;">
          </swiper-slide>

          <swiper-slide v-for="(item, idx) in baners" :key="idx" class="banner">
            <a href="javascript:" class="banner-link" @click="moveLink(item.banerUrl)">
              <template v-if="item.type === 'video'">
                <div class="banner-visual">
                  <video :src="item.mobiThumb" autoplay="true" muted="muted" loop="" playsinline></video>
                </div>
                <div class="banner-content">
                  <h2 v-if="item.banerCn" class="title">{{ item.banerCn }}</h2>
                </div>
              </template>
              <img v-else :src="item.mobiThumb" alt="" style="width: 100%;">
            </a>
          </swiper-slide>
          <div class="banner-pagination swiper-pagination"></div>
        </swiper>
      </article>

      <!-- content-section -->
      <article class="content-section section-my">
        <MySection my-class-name="my-content" :is-kb-bank="isKbBank"/>
      </article>

      <!-- content-section: 오늘 -->
      <article class="content-section section-today">
        <header class="section-header d-flex">
          <h2 class="title">오늘</h2>
          <div class="header-side">
            <span class="text text-muted">{{ getTodayDate('년월일') }}</span>
          </div>
        </header>
        <TodayList :is-kb-bank="isKbBank"/>
      </article>

      <article class="content-section section-hottip">
        <header class="section-header d-flex">
          <h2 class="title">Hot Tip[핫;팁]</h2>
          <div class="header-side">
              <router-link :to="{name: 'HotTipMain'}" class="text-gold">Hot Tip 바로가기</router-link>
          </div>
        </header>
        <template v-if="hottipLoading">
          <LoadingDiv v-model="hottipLoading"/>
        </template>
        <template v-else>
          <div class="board-preview-list">
            <ul class="board-preview-table">
              <li v-for="(item,idx) in hotTipMyPreviewList" :key="idx" class="kb-table-row" :class="{'opacity-50': item.isRead === 'Y'}">
                <!--                  <div class="kb-table-cell category-area">-->
                <!--                    <span class="text-gold">{{item.cateNm}}</span>-->
                <!--                  </div>-->
                <div class="kb-table-cell title-area">
                  <div class="d-flex align-items-center">
                    <span class="text kb-mouse-cursor" :title="item.postTitle" @click="moveToBoardDtl(item)">{{item.postTitle}}</span>
                    <i v-if="item.isNew === 'Y'" class="icon-new"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </article>

      <!-- content-section: HRD 클라우드 -->
      <article v-if="isKbBank" class="content-section section-hrdcloud">
        <header class="section-header d-flex">
          <h2 class="title">HRD 클라우드</h2>
          <div class="tabs">
            <a v-for="(item, idx) in mainList" :key="idx" href="javascript:" class="tab"
               :class="{'is-active' : hrdCloudFilter === item.name}" @click="hrdCloudFilter = item.name">
              {{ item.title }}
            </a>
          </div>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="hrdLoading">
            <LoadingDiv v-model="hrdLoading"/>
          </template>
          <template v-else>
            <HrdCloudMobileSwiper :items="items" :session="session" :show-put="true" />
<!--            <swiper-->
<!--                v-if="items"-->
<!--                class="hrdcloud-list"-->
<!--                :modules="modules"-->
<!--                slides-per-view="auto"-->
<!--                :navigation="{-->
<!--                  nextEl: '.kb-btn-badge-next',-->
<!--                  prevEl: '.kb-btn-badge-prev'-->
<!--                }"-->
<!--            >-->
<!--              <swiper-slide v-for="(item, idx) in items" :key="idx" class="hrdcloud-item">-->
<!--                <template v-if="item">-->
<!--                  <HrdCloudContent-->
<!--                      :item="item"-->
<!--                      :idx="idx"-->
<!--                      :session="session"-->
<!--                      :is-put-action="false"-->
<!--                  />-->
<!--                </template>-->
<!--              </swiper-slide>-->
<!--            </swiper>-->
          </template>
        </div>
        <!-- //hrdcloud-list-wrapper -->
        <div class="section-bottom bottom-buttons">
          <router-link :to="{name: 'HrdCloud'}" class="kb-btn kb-btn-outline">
            <span class="text">HRD 클라우드 바로가기</span>
          </router-link>
        </div>
      </article>

      <article v-if="isKbBank" class="content-section section-hrdcloud">
        <header class="section-header d-flex">
          <h2 class="title">SSL 학습노트</h2>
          <div class="tabs">
            <a v-for="(item, idx) in sslList" :key="idx" href="javascript:" class="tab"
               :class="{'is-active' : sslCardFilter === item.name}" @click="sslCardFilter = item.name">
                {{ item.title }}
            </a>
          </div>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="sslLoading">
            <LoadingDiv v-model="sslLoading"/>
          </template>
          <template v-else>
            <!-- board-list -->
            <template v-if="sslitems.length > 0">
              <SSLNoteCard :slides-per-view='"auto"'
                           :sslItems="sslitems"/>
            </template>
            <template v-else>
              <div class="search-container">
                <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                    src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">구독직원의 SSL 학습노트가 없습니다.</p>
                </div>
              </div>
            </template>
          </template>

        </div>

        <div class="section-bottom bottom-buttons">
          <button class="kb-btn kb-btn-outline" @click="moveSSL">
            <!--          <button class="kb-btn kb-btn-outline" @click="underConstruction">-->
            <span class="text">SSL 바로가기</span>
          </button>
        </div>
      </article>

      <!-- content-section -->
      <article v-if="isKbBank" class="content-section section-square">
        <div class="square-container">
          <SquareList/>
        </div>
      </article>
    </div>
    <MobileBanerPopupModal
        v-if="popups.length > 0 && !expirationPopup"
        :items="popups"
    />
  </main>

</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import MySection from '@/components/main/MySection';
import SquareList from '@/components/main/SquareList';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {useStore} from 'vuex';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import TodayList from '@/components/main/TodayList';
import {getItem, getItems, getTodayDate, lengthCheck, stringCheck, timestampToDateFormat} from '@/assets/js/util';
import {
  banerDivCdDcds,
  banerExpsrLocCdDcds,
  getBanerList,
  getJuniorBaner,
} from '@/assets/js/modules/baner/common-baner';
import SSLNoteCard from '@/components/main/SSLNoteCard';
import {useAlert} from '@/assets/js/modules/common/alert';
import MobileBanerPopupModal from '@/components/main/mobile/MobileBanerPopupModal';
import {getDistCrseCd, sslRoutes, ssoKB} from '@/assets/js/modules/ssl/ssl-common';
import {goLink, moveSSL} from '@/assets/js/modules/common/common';
import LoadingDiv from '@/components/common/LoadingDiv.vue';
import mobileUtils, {isInApp, isInAppIOS} from "@/assets/js/navigationUtils";
import {
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST} from "@/store/modules/board/board-mst";
import {getPostList} from "@/assets/js/modules/board/board-common";
import router from '@/router';
import HrdCloudMobileSwiper from "@/components/hrdcloud/mobile/HrdCloudMobileSwiper.vue";

export default {
  name: "MobileMain",
  components: {
    HrdCloudMobileSwiper,
    LoadingDiv,
    MobileBanerPopupModal,
    SSLNoteCard, TodayList, SquareList, MySection, Swiper, SwiperSlide
  },
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001')&& session.value.jbpsCd !=='A99');
    const bannerVideo = require('../../../assets/lxp/videos/first_banner.mp4');
    const bannerImg = ref(`${process.env.VUE_APP_CLOUDFRONT_URL}/contents/hmpg/baner/mobithumb/2022_mob_year.jpg`);
    const hrdCloudFilter = ref('latest');
    const isBannerLoading = ref(false);


    let nowDate = new Date();
    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-main')) > nowDate);

    const mainList = ref([
      {name: 'latest', title: '신규', params: {category1: 2}, isLoading: true, items: []},
      {name: 'latestPopularDuty', title: '인기', params: {category1: 1}, isLoading: true, items: []},
      {name: 'recommended', title: '추천', params: {category1: 1}, isLoading: true, items: []},
    ]);

    const paging = reactive({pageNo: 1, pageSize: 6});

    const hrdLoading = ref(true);
    const items = ref([]);
    const latestItems = ref([]);
    const popularDutyItems = ref([]);
    const recommendedItems = ref([]);

    const sslCardFilter = ref('excellent');
    const sslLoading = ref(false);
    const hottipLoading = ref(false);
    const sslList = ref([
      {name: 'excellent', title: '인기', isLoading: true, items: []},
      {name: 'subscribe', title: '구독', isLoading: true, items: []},
    ]);
    const sslitems = ref([]);
    const excellentItems = ref([]);
    const subscribeItems = ref([]);
    //hottip 정보
    const hotTipMyPreviewList = ref([]);
    // 쓸이 종료된 경우 구독 학습노트 숨김 처리
    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if(res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if (lengthCheck(res)) {
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
            }
          });
        }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'subscribe') {
        if (!stringCheck(subscribeItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
            if (lengthCheck(res)) {
              console.log("res.value");
              console.log(getItems(res));
              subscribeItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
              console.log("subscribe.value");
              console.log(sslitems.value);
            }
          });
        }
        sslitems.value = subscribeItems.value;
      }
      sslLoading.value = false;
    };

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    getsslCardList(sslCardFilter);


    const underConstruction = () => {
      return showMessage('학습기간이 아닙니다.');
    }

    const getHrdCloudList = async (hrdCloudFilter) => {
      hrdLoading.value = true;
      if (hrdCloudFilter.value === 'latest') {
        if (!stringCheck(latestItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {category1: 2, ...paging});
          latestItems.value = getItems(res);
        }
        items.value = latestItems.value;
      } else if (hrdCloudFilter.value === 'latestPopularDuty') {
        if (!stringCheck(popularDutyItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: '', ...paging});
          popularDutyItems.value = getItems(res);
        }
        items.value = popularDutyItems.value;
      } else if (hrdCloudFilter.value === 'recommended') {
        if (!stringCheck(recommendedItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, paging);
          recommendedItems.value = getItems(res);
        }
        items.value = recommendedItems.value;
      }
      hrdLoading.value = false;
    };

    const moveToBoardDtl = (item) => {
      router.push({name:'BoardView',params:{'boardId':item.boardId, 'boardSecuKey':item.boardSecuKey}});
    }
    const getHotTipList = async (boardId)=>{
      hottipLoading.value = true;
      let boardMst = null;
      let boardCateList = [{cateNm:'전체', boardCateSn:''}];
      await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
        boardMst = getItem(res);
        await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
          boardCateList = boardCateList.concat(getItems(res));
        }).finally(async () => {
          await getPostList({boardMstSn:boardMst.boardMstSn, topFixYn:'N', pageNo:1, pageSize:5}, hotTipMyPreviewList, null, boardCateList);
        })
      });

      hottipLoading.value = false;

    }

    getHotTipList('hottip-my');
    getHrdCloudList(hrdCloudFilter);

    watch(() => hrdCloudFilter.value, () => {
      getHrdCloudList(hrdCloudFilter);
    });

    const baners = ref([]);
    getBanerList({banerDivCdDcd: banerDivCdDcds.baner, banerExpsrLocCdDcd: banerExpsrLocCdDcds.main}, baners, () => {
      isBannerLoading.value = true;
      if (session.value.pmsnSn === 5) {
        getJuniorBaner(baners.value);
      }
    });

    const popups = ref([]);
    // 팝업
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.main,
      pageNo: 1,
      pageSize: 10
    }, popups);

    if (isInApp && (mobileUtils.iOS() || isInAppIOS)) {
      const jobJson = {
        badge: "0"
      }
      location.href = 'iOSNativeCall://UpdateBadge?' + JSON.stringify(jobJson);
    }

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const slideHeight = ref(194);

    const slideStyle = computed(() => {
      return {'height': `${slideHeight.value}px`}
    });


    const isBeginning = ref(true);
    const isEnd = ref(false);

    const moveSlide = (isNext) => {
      if (controlledSwiper.value) {
        if (isNext) {
          controlledSwiper.value.slideNext();
        } else {
          controlledSwiper.value.slidePrev();
        }
      }
    }
    const slideChange = (swiper) => {
      isBeginning.value = swiper.isBeginning;
      isEnd.value = swiper.isEnd;
    }

    const moveLink = (banerUrl) => {
      if (!banerUrl) {
        return false;
      } else {
        if (banerUrl === 'junior') {
          ssoKB(banerUrl);
        } else {
          goLink(banerUrl);
          // router.push({redirect: window.location.href = banerUrl});
        }
      }
    }
    // localStorage.removeItem('kb-popup-main');

    return {
      mainList,
      items,
      hrdCloudFilter,
      session,
      baners,
      popups,
      slideStyle,
      hrdLoading,
      isBeginning,
      isEnd,
      bannerVideo,
      bannerImg,
      moveSlide,
      slideChange,
      setControlledSwiper,
      showMessage,
      getTodayDate,
      moveLink,
      moveSSL,
      expirationPopup,
      isBannerLoading,
      isKbBank,
      modules: [Navigation],
      underConstruction,
      sslRoutes,
      sslCardFilter,
      sslList,
      sslitems,
      sslLoading,
      moveToBoardDtl,
      hottipLoading,
      hotTipMyPreviewList,

    }
  }
}
</script>